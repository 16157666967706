import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image"
import BrandedDivider from "../components/branded_divider"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {
  cardTitle,
  cardContent
} from "../css/index.module.css"
import PageBanner from "../components/page_banner"
import {SEO} from "../components/seo"

const ServicesPage = () => {
  return (
    <Layout pageTitle="Services">
      <PageBanner title="A Rounded Approach">
        <StaticImage
          placeholder="blurred"
          alt="EnginuityUSA - Services"
          src="../images/services.jpg"
          objectFit="cover"
        />
      </PageBanner>

      <Container className="py-5">
        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>COMPREHENSIVE DESIGN BUILD SERVICES</h4>

            <p className={cardContent}>Enginuity Construction LLC puts a
              great deal of time and effort into every project. Whether it's a small restaurant, or a multi
              million dollar high rise building, our level of
              commitment and attention to detail remains
              the same.
            </p>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>DESIGN - BUILD</h4>

            <p className={cardContent}>Providing a top-notch design team to prepare the project documents in a cost effective and timely
              manner is the cornerstone of getting a project off to a good start. Most of the engineering disciplines we
              have in-house and on occasion team with other design professionals to provide design development on
              larger projects.
            </p>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>BUILD</h4>

            <p className={cardContent}>Putting together a cracker jack team of sub-contractors with the experience and expertise to execute
              the project plan and work together to coordinate their construction activities to meet schedule and cost
              requirements are at the core of our project execution mission. From site prep to final punch list our
              team is on the job to assure our project execution meets your objectives.
            </p>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>PROJECT MANAGEMENT</h4>

            <p className={cardContent}>
              Project Management is critical to the success of any construction project. Monitoring and controlling
              costs and schedule will determine the success or failure of a project plan. Enginuity applies the principles
              of project management as set forth in the most up to date project management resources. Primary to
              project management is stakeholder involvement in the design and construction process. Keeping the
              stakeholders informed is vital to the successful execution of the project plan. We at Enginuity pride
              ourselves on effective communication, organization, and project control.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}


export const Head = () => <SEO title={"Enginuity GC | Services"}/>

export default ServicesPage
